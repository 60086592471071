.section-customer-sm {
  .section-content {
    padding: 20px 0 15px;

    @include desktop {
      padding: 31px 0 37px;
    }
  }

  .customer {
    display: flex;
    padding-left: 6px;

    @include desktop {
      padding-left: 11px;
    }

    &__img {
      width: 16px;
      padding-top: 1px;
      margin-right: 8px;
      flex-shrink: 0;

      @include desktop {
        padding-top: 4px;
        margin-right: 16px;
        width: 24px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &__body {
      line-height: 1;
    }

    &__company {
      margin-bottom: 5px;
    }

    &__name {
      font-size: 1.8rem;
      margin-bottom: 8px;
      word-break: break-all;

      @include desktop {
        font-size: 3rem;
        margin-bottom: 11px;
      }

      &::after {
        content: '様';
        margin-left: 0.6rem;
        font-size: 1.2rem;

        @include desktop {
          font-size: 1.6rem;
          margin-left: 1.1rem;
        }
      }
    }

    &__address {
      font-size: 0.9rem;
      font-weight: normal;

      @include desktop {
        font-size: 1.2rem;
      }
    }
  }
}
