*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  font-size: $global-font-size;
  -webkit-font-smoothing: antialiased;
}

/*
  タイポグラフィの微調整
  4. アクセシブルなline-heightを追加
  5. テキストのレンダリングを改善
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: $base-font-size;
  font-family: $font__gothic;
  font-weight: bold;
  letter-spacing: 0;
  font-feature-settings: 'palt';
  text-rendering: optimizeLegibility;
  line-height: $base-line-height;
  color: $base-font-color;

  @include mobile {
    // font-size: $base-font-size-small;
  }

  @include desktop {
    font-size: 1.6rem;
  }
}

main,
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/*
  6. メディア要素のデフォルトを改善
*/
// img, picture, video, canvas, svg {
//   display: block;
//   max-width: 100%;
// }

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-weight: normal;
  font-style: normal;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
img {
  vertical-align: bottom;
  border-style: none;
  max-width: 100%;
  height: auto;
}

/*
  form用resetCSS
*/

input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='password'] {
  padding: 0;
  border: none;
  border-radius: 0;
  background: none;

  &.is-invalid {
    outline: 1px solid #a40000;
  }

  &:focus {
    outline: 1px solid $primary-color;
  }
}
input[type='radio'],
input[type='checkbox'] {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  border-radius: 0;

  &.is-invalid {
    outline: 1px solid #a40000;
  }

  &:focus {
    outline: 1px solid $primary-color;
  }
}
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  background: transparent;
  border-radius: 0;

  &.is-invalid {
    outline: 1px solid #a40000;
  }

  &:focus {
    outline: 1px solid $primary-color;
  }
}

button,
input[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 0;
}

button {
  cursor: pointer;
}

.invalid-feedback {
  color: #a40000;
  font-size: 1rem;
  margin-top: 0.2em;

  @include desktop {
    font-size: 1.2rem;
  }
}

#wpadminbar {
  display: none;
}
