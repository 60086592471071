.modal {
  margin-top: $header-height;
  padding-right: 25px;
  padding-bottom: $header-height;
  padding-left: 25px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  justify-content: center;
  align-items: center;

  @include desktop {
    margin-top: $header-height-pc;
    padding-bottom: $header-height-pc;
  }

  &.is-open {
    display: flex;
  }

  &[aria-hidden='false'] {
    animation: mmfadeIn 0.2s $easeInOutSine;
  }

  &.modal-copy,
  &.modal-message {
    opacity: 0;
    transition: 0.2s $easeInOutSine;

    &.is-show {
      opacity: 1;
    }
  }

  .confirm {
    padding: 18px 23px;
    background: #333;
    color: #fff;
    border-radius: 5px;
    border: 2px solid #8c8c8c;
    box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);

    &__text {
      text-align: center;
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
      font-size: 1.1rem;
      margin-top: 1.3rem;
    }

    .btn {
      background: #f6f8fa;
      border: 0.5px solid #595959;
      min-width: 75px;
      border-radius: 5px;
      padding: 0.42em;
      color: inherit;
      background-color: transparent;

      @include desktop {
        transition: 150ms $easeInOutSine;
      }

      &:hover {
        @include desktop {
          opacity: 0.7;
        }
      }

      &.danger {
        background-color: #a40000;

        &:hover {
          @include desktop {
            color: #fff;
            background: #cf222e;
          }
        }
      }

      + .btn {
        margin-left: 10px;
      }
    }
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.js-modal-close-copy,
.js-modal-close-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 11;
}
