.inview {
  opacity: 0;
  position: relative;

  &.slideIn {
    // transform:translate(0px, 60px);
    top: 60px;

    &.bottom {
      transition: all 1600ms $easeOutExpo;
      transform: translate(0px, 120px);
    }
    &.animate {
      opacity: 1;
      // transform:translate(0px, 0px);
      top: 0;
      transition: all 800ms $easeOutCubic;
    }
  }

  &.fadeIn {
    &.animate {
      opacity: 1;
      transition: opacity 1500ms $ease;
    }
  }
}

.masker {
  display: inline-block;
  overflow: hidden;
  .typo {
    transform: translate(0, 100%);
    transition: transform 1600ms $easeInOutQuart;
  }
  &.animate {
    .typo {
      transform: translate(0, 0%);
    }
  }
}
