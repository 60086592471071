.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: $header-height;
  background-color: $primary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;

  @include desktop {
    height: $header-height-pc;
    padding: 0 36px;
  }
}

.hamburger-btn {
  width: 44px;
  height: 44px;
  padding: 16.5px 4.5px;
  position: relative;
  z-index: 2;

  &__inner {
    position: relative;
    display: block;
    height: 100%;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      height: 3px;
      background-color: #fff;
      border-radius: 1.5px;
      transition: all 0.3s $easeOutQuint;
    }

    &::before {
      top: 0;
      width: 100%;
    }

    &::after {
      bottom: 0;
      width: 25px;
    }
  }

  &.is-open {
    .hamburger-btn__inner {
      &::before {
        top: 50%;
        transform: translateY(-50%) rotate(30deg);
      }

      &::after {
        bottom: 50%;
        transform: translateY(50%) rotate(-30deg);
        width: 100%;
      }
    }
  }
}

.header-padding {
  height: $header-height;

  @include desktop {
    height: $header-height-pc;
  }
}
