.globalnav {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 1;
  height: 100vh;
  background-color: $primary-color;
  color: #fff;
  padding-top: $header-height;
  transition: transform 0.4s $easeOutQuint;

  &.is-open {
    transform: translateX(-100%);
  }

  @include desktop {
    padding-top: $header-height-pc;
  }

  .content {
    padding: 40px;

    @include desktop {
      padding: 40px 70px;
    }
  }

  .gnav-list {
    .gnav-item + .gnav-item {
      margin-top: 15px;
    }
  }

  .gnav-item a {
    white-space: nowrap;
    color: #fff;
    font-size: 1.4rem;
    padding: 8px 0;
    display: block;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.5;
    }

    @include desktop {
      font-size: 1.8rem;
    }
  }
}
