.noscroll {
  overflow: hidden;
}

.wrap {
  background-color: $green-200;
  min-height: 100vh;
}

.bg-wht {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);

  @include desktop {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  }

  &_login {
    @include desktop {
      box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
    }
  }
}

.primary-btn {
  color: #fff;
  background-color: $primary-color;
  display: block;
  width: 100%;
  max-width: 235px;
  font-size: 1.5rem;
  border-radius: 5px;
  text-align: center;
  padding: 1.15rem 1.5rem;
  box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.2);

  @include desktop {
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-out;

    &:hover {
      transform: translate(0, 5px);
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    color: #fff;
  }
}

.js-effect-btn {
  overflow: hidden;
  position: relative;

  .effect-wrap {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    width: 200%;
    padding-top: 200%;
    pointer-events: none;
  }

  .effect {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    transform: scale(0.2, 0.2);
    border-radius: 50%;
    transition: all 0.8s $easeOutSine;

    &_small {
      transition: all 0.4s $easeOutSine;
    }
  }
}

.rounded-btn {
  padding: 8.2px 10px;
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  width: 100%;
  border-radius: 20px;

  &:hover {
    @include mobile {
      color: #fff;
    }
  }

  &_blk {
    background-color: #262626;

    &.is-open {
      background-color: #fff;
      color: #262626;
      border-color: #d9d9d9;
    }
  }
}

.back-btn {
  font-size: 0.9rem;
  position: relative;
  background-color: #262626;
  color: #fff;
  padding: 6px 10px 6px 30px;
  display: inline-block;
  width: 100%;
  max-width: 80px;
  text-align: center;
  border-radius: 15px;

  @include desktop {
    font-size: 1.4rem;
    max-width: 120px;
    padding: 7.4px 15px 7.4px 35px;
    border-radius: 20px;
  }

  &:hover {
    color: #fff;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    z-index: 1;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #515151;
    border-radius: 50%;

    img {
      width: 10px;
    }

    @include desktop {
      width: 30px;
      height: 30px;

      img {
        width: 13px;
        transition: transform 0.3s $easeOutQuint;
      }
    }
  }

  &:hover {
    .back-btn__arrow {
      img {
        transform: translateX(-3px);
      }
    }
  }
}

.quotation-data {
  line-height: 1;

  @include desktop {
    display: flex;
    align-items: center;
  }

  &__num {
    margin-bottom: 9px;
    display: flex;
    align-items: center;

    @include desktop {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 1.8rem;
    }
  }

  &__label {
    margin-left: 7px;
  }

  &__param {
    font-weight: normal;
    font-size: 0.9rem;

    @include desktop {
      font-size: 1.2rem;
    }
  }
}

.wrap {
  .field {
    display: block;
    width: 100%;
    padding: 0 1.6rem;
    height: 4rem;
    background-color: #fafafa;
    border-radius: 5px;
    border: 1px solid #e5e5e5;

    @include desktop {
      height: 6rem;
    }
  }
}

.new-label {
  padding: 4px 7px 4px 10px;
  margin-top: -4px;
  margin-bottom: -4px;
  display: inline-block;
  font-size: 0.8rem;
  background-color: #da294a;
  color: #fff;
  clip-path: polygon(6px 0%, 100% 0%, 100% 100%, 6px 100%, 0% 50%);
}

.error {
  color: #a40000;

  @include desktop {
    font-size: 1.4rem;
  }
  @include mobile {
    font-size: 1.2rem;
  }

  &_req,
  &_num {
    display: none;

    &.is-active {
      display: block;
    }
  }
}
