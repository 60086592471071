.pagetop {
	position: fixed;
	right: 20px;
  bottom: 0;
  z-index: 9;


  a {
    display: block;
    padding: 20px 0;

    text-align: center;
		// font-size: 1.0rem;
		font-weight: bold;
    position: relative;
    // background-image: url(../img/common/pagetop.png);
    background-size: cover;
    width: 114px;
    height: 95px;
    @include mobile {
      width: 70px;
      height: 60px;
    }

  }

}
