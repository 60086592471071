.masthead {
  max-width: 127px;

  @include desktop {
    max-width: 153px;
  }

  .brand-name {
    display: block;
  }
}
