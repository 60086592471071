.loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #eef3e3;

  &.is-loaded {
    transition: opacity 500ms $easeInOutSine,
    pointer-events 0ms $easeInOutSine 500ms,
    visibility 0ms $easeInOutSine 500ms;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  &-icon {
    position: absolute;
    bottom: 0;
    opacity: 0;
    // transform: scale(0);
    transform-origin: center bottom;

    &.icon-01 {
      left: 0;
      width: calc(50/75 * 100%);
    }

    &.icon-02 {
      right: 0;
      width: calc(33/75 * 100%);
    }

    &-wrap {
      position: relative;

      @include desktop {
        width: 150px;
        height: 120px;
      }
      @include mobile {
        width: 75px;
        height: 60px;
      }
    }
  }

  &-text {
    @include desktop {
      max-width: 300px;
    }
    @include mobile {
      max-width: 150px;
    }

    &.overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      transition: clip-path 100ms;
    }

    &-wrap {
      position: relative;

      @include desktop {
        margin-top: 20px;
      }
      @include mobile {
        margin-top: 10px;
      }
    }
  }
}